@media screen and (max-width: 900px) {
    #resp_header-wrapper {
        flex-direction: column;
        height: fit-content;
        padding: 25px 0px;
        align-items: center;
        justify-content: center;
    }
    #resp_name-wrapper, #resp_menu-wrapper {
        margin: auto;
    }
    #resp_name-text {
        font-size: 28px;
    }
    #resp_nav-text {
        font-size: 18px;
    }
}

@media screen and (max-width: 450px) {

    #resp_menu-wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 360px) {
    #resp_name-text {
        font-size: 24px;
    }
}

.header-wrapper {
    width: 100%;
    height: 75px;
    display: flex;
    margin-top: 50px;
}

.name-wrapper {
    margin: auto auto auto 10%;
    font-family: clarendon-wide, serif;
    font-weight: 800;
    width: fit-content;
}

.name-text {
    font-size: 28px;
}

.menu-wrapper {
    margin: auto 10% auto auto;
    display: flex;
    height: 100%;
    width: fit-content;
    border-radius: 12px;
}

.nav-link {
    width: 100px;
    text-decoration: none;
    margin: auto 20px;
    height: 80%;
    padding: 0px 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: clarendon-wide, serif;
    color: white;
    font-family: 'Red Hat Display', 'sans-serif';
}


.works-link-active, .bio-link-active {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.1);

    background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
      linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
      linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
      linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    
    background-repeat: no-repeat;
    background-size: 8px 5px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: 5px solid rgb(255, 255, 255);
    border-top: none;
    border-bottom: none;
    
}

#bio-link {
    color: grey;
}


.bio-link-active {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.5);

}


.route-wrapper {
    width: 100%;
}