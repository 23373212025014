@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  color: white;
  background-color: rgba(0, 0, 0);
  background-color: #101010;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23eeeeee' fill-opacity='0.25'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  font-family: 'Red Hat Display', 'sans-serif';
}
@media screen and (max-width: 900px) {
    #resp_header-wrapper {
        flex-direction: column;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 25px 0px;
        align-items: center;
        justify-content: center;
    }
    #resp_name-wrapper, #resp_menu-wrapper {
        margin: auto;
    }
    #resp_name-text {
        font-size: 28px;
    }
    #resp_nav-text {
        font-size: 18px;
    }
}

@media screen and (max-width: 450px) {

    #resp_menu-wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 360px) {
    #resp_name-text {
        font-size: 24px;
    }
}

.header-wrapper {
    width: 100%;
    height: 75px;
    display: flex;
    margin-top: 50px;
}

.name-wrapper {
    margin: auto auto auto 10%;
    font-family: clarendon-wide, serif;
    font-weight: 800;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.name-text {
    font-size: 28px;
}

.menu-wrapper {
    margin: auto 10% auto auto;
    display: flex;
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 12px;
}

.nav-link {
    width: 100px;
    text-decoration: none;
    margin: auto 20px;
    height: 80%;
    padding: 0px 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: clarendon-wide, serif;
    color: white;
    font-family: 'Red Hat Display', 'sans-serif';
}


.works-link-active, .bio-link-active {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.1);

    background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
      linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
      linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255)),
      linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    
    background-repeat: no-repeat;
    background-size: 8px 5px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: 5px solid rgb(255, 255, 255);
    border-top: none;
    border-bottom: none;
    
}

#bio-link {
    color: grey;
}


.bio-link-active {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.5);

}


.route-wrapper {
    width: 100%;
}
@media screen and (max-width: 650px) {
    #resp_bio-core-wrapper, #resp_top-portion {
        flex-direction: column;
    }
    #prof_pic, #resp_pdf {
        margin: 0px auto;
    }
    #resp_intro-text {
        margin: 25px auto;
    }
    #resp_bio-intro-text {
        width: 80%;
    }
}

@media screen and (max-width: 850px) {
    #resp_bottom {
        flex-direction: column;
    }
    #edu_accordion, #skills_accordion, #exp_accordion {
        width: 75%;
        margin: 25px auto;
    }
}

.bio-core-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 50px 0px;
    margin-top: 50px;
}

.top-portion {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.prof_photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto 0px auto auto;
    background-image: url(/static/media/prof_photo.456da146.png);
    background-size: contain;
}

.bio-intro-text {
    background-image: linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151));
    
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: solid rgb(255, 47, 151);
    text-align: justify;
    border-width: 0 3px;
    padding: 20px;
    width: 50%;
    margin: 0px auto;
    text-align-last: center;
    font-size: 16px;
}


.intro-text {
    background-image: linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151));
    
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: solid rgb(255, 47, 151);
    text-align: justify;
    border-width: 0 3px;
    padding: 20px;
    width: 50%;
    margin: 0px auto;
    text-align-last: center;
    font-size: 16px;
}

.pdf-dl-wrapper {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(100, 100, 100);
    margin: auto auto auto 0px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
}

.pdf-dl-wrapper:hover {
    -webkit-filter: brightness(2.5);
            filter: brightness(2.5);
    color: black;
    font-weight: bolder;
}

.pdf-dl-wrapper:active {
    -webkit-filter: brightness(2);
            filter: brightness(2);
    color: red;
    font-weight: bolder;
}

/* bottom portion css */

.bottom-portion {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
}

.accordion-wrapper {
    width: 35%;
    background-color: rgba(0, 0, 0);
    border: none;
    border-radius: 4px;
    text-align: left;
    outline: none;
    transition: 0.4s ease-in-out;
    margin: 0px auto auto auto;
}

.btn-wrap {
    display: flex;
    align-items: center;
    background-color: rgba(25, 25, 25);
    border-radius: 4px 4px 0px 0px;
    height: 40px;
    cursor: pointer;
}

.accordion-btn {
    width: 10%;
    height: 100%;
    border: none;
    background: none;
    color: rgb(255, 0, 221);
    cursor: pointer;
    font-size: 20px;
    font-weight: bolder;
}

.chevron-rotate {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.btn-wrap:hover {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

.btn-title {
    width: 90%;
    text-align: left;
    margin: auto 0px auto auto;
    font-family: clarendon-wide, sans-serif;
    color: rgb(255, 0, 221);
}

.btn-title-active {
    font-weight: bolder;
}

.accordion-panel {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 20px;
    background-color: rgba(50, 50, 50);
    border-radius: 0px 0px 4px 4px;
    display: none;
}

.panel-wrap {
    margin: 0;
    text-align: justify;
}

.panel_toggle {
    display: block;
    transition: 0.2s ease-in-out;
}

.panel-list-title {
    font-weight: bolder;
}

.panel-list-item {
    list-style-type: none;
    font-size: 16px;
}

.panel-sub-item {
    font-size: 14px;
}

.extra-title {
    color: rgb(255, 0, 221);
    font-weight: bolder;
}

.skills-item {
    font-size: 16px;
    font-weight: bold;
}

.sub-item {
    list-style-type: none;
}

.sub-item:before {
    content: "\00BB";
}
@media screen and (max-width: 650px) {
    #resp_module-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #resp_email-module {
        width: 75%;
    }
    #resp_social-module {
        margin-top: 50px;
        width: 75%;
    }
    #resp_display-wrap {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {
    #resp_display-wrap {
        width: 75%;
    }
}

.contact-wrapper {
    width: 75%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
}

.contact-title-wrap {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: auto;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.display-wrapper {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.02);
    border-radius: 8px;
    padding: 0px 20px;
    width: 50%;
    text-align: center;
}

.module-wrapper {
    display: flex;
    width: 100%;
    padding: 20px 0px;
    margin: 50px 0px;
}

.email-module-wrap {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.contact-input-field {
    height: 35px;
    border-radius: 6px;
    margin: 0px 0px 10px 0px;
    padding: 5px;
    border: none;
    color: rgb(255, 77, 106);
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.02);
}

::-webkit-input-placeholder {
    color: rgb(201, 201, 201);
}

:-ms-input-placeholder {
    color: rgb(201, 201, 201);
}

::placeholder {
    color: rgb(201, 201, 201);
}

#msg-input {
    height: 75px;
    padding: 10px;
    margin: 10px 0px;
    font-family: Arial, sans-serif;
}

.submit-btn {
    background-color: orange;
    color: white;
    height: 40px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
}

.submit-btn:hover {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    color: black;
}

.submit-btn:active {
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
    color: black;
}

/* right portion - link side */

.social-module-wrap {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.phone-img, .linkedin-img {
    width: 35px;
    height: 35px;
}

.phone-wrap, .linkedin-wrap {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: baseline;
}

.phone-img {
    background-image: url(/static/media/phone-square-solid.e705ef86.svg);
    background-size: cover;
}

.linkedin-img {
    background-image: url(/static/media/linkedin-brands.f5eb33e4.svg);
    background-size: cover;
}

.social-contact-text {
    margin-left: 20px;
    cursor: pointer;
}

#linkedin_link:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

#linkedin_link:active {
    -webkit-filter: brightness(0.75);
            filter: brightness(0.75);
}

.linkedin-link {
    text-decoration: none;
    color: white;
}
@media screen and (max-width: 650px) {
    #resp_works-wrapper {
        margin-top: 0px;
    }
}

@media screen and (max-width: 850px) {
    #resp_proj-menu-wrap {
        width: 75%;
        top: 25%;
    }
}

@media screen and (max-width: 550px) {
    #resp_works-wrapper {
        padding: 0;
    }
    #resp_proj-menu-wrap {
        flex-direction: column;
        margin: auto;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        position: static;
        -webkit-transform: none;
                transform: none;
        top: none;
        left: none;
    }
}


.works-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 50px 0px;
    margin-top: 50px;
}

.proj-menu-wrap {
    width: 50%;
    height: 50px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.proj-nav-link {
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: rgba(100, 100, 100);
    
}


/* projects active and hover links */

.blog-link-active {
    color: black;
    text-shadow:
        0 0 5px rgb(255, 115, 0),
        0 0 5px rgb(255, 115, 0),
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0);
}

#blog-nav-link:hover {
    color: rgb(255, 166, 0);
}

.turners-link-active {
    color: black;
    text-shadow:
        0 0 5px #ea3642,
        0 0 5px #ea3642,
        0 0 5px #ea3642, 
        0 0 5px #ea3642, 
        0 0 5px #ea3642, 
        0 0 5px #ea3642, 
        0 0 5px #ea3642, 
        0 0 5px #ea3642;
}

#level-nav-link:hover {
    color: #ea3642;
}

.levelup-link-active {
    color: black;
    text-shadow:
        0 0 5px #00bdc4,
        0 0 5px #00bdc4,
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4;
}

#level-nav-link:hover {
    color: #43C0F6;
}

.gate-link-active {
    color: black;
    text-shadow:
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255);
}

#gate-nav-link:hover {
    color: rgb(203, 89, 255);
}

.ux-link-active {
    color: black;
    text-shadow:
        0 0 3px rgb(0, 220, 150),
        0 0 3px rgb(0, 220, 150),
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150);
}

#ux-nav-link:hover {
    color: rgb(0, 118, 0);
}

/* end */

.proj-link-text {
    font-weight: bolder;
    font-size: 14px;
}

/* content wrapper css */

.project-content-wrapper {
    width: 100%;
    padding: 25px 0px;
    margin: 50px auto;
}
@media screen and (max-width: 1250px) {
    #resp_demo {
        width: 900px;
        height: 580px;
    }
}

@media screen and (max-width: 950px) {
    #resp_demo {
        width: 720px;
        height: 480px;
    }
}

@media screen and (max-width: 750px) {
    #resp_demo {
        width: 600px;
        height: 420px;
    }
    #resp_proj-title {
        font-size: 42px;
    }
    #resp_brief-text {
        width: 90%;
    }
}

@media screen and (max-width: 620px) {
    #resp_demo {
        width: 450px;
        height: 280px;
    }
}

@media screen and (max-width: 480px) {
    #resp_demo {
        width: 320px;
        height: 180px;
    }
    #resp_proj-title {
        font-size: 28px;
    }
    #resp_brief-text, #resp_url, #resp_github {
        font-size: 14px;
    }
}

.project-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-title {
    font-size: 52px;
    text-align: center;
    padding: 0 20px;
}

.project-brief-text {
    background-image: linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151));
    
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: solid rgb(255, 47, 151);
    border-width: 0 3px;


    padding: 20px;
    width: 60%;
    margin: 20px auto;
    text-align: justify;
    text-align-last: center;
    font-size: 16px;
}

.live-url-text {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    color: blueviolet;
}

.live-url-link {
    color: blueviolet;
    text-decoration: none;
}

.live-url-link:hover, .github-link:hover {
    -webkit-filter: brightness(2);
            filter: brightness(2);
    text-decoration: underline;
    text-underline-offset: 5px;
}

.github-link-text {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    color: greenyellow;
}

.github-link {
    color: greenyellow;
    text-decoration: none;
}

.demo-video-wrap {
    position: relative;
    overflow: hidden;
    margin: 50px auto;
    border-radius: 12px;
    width: 1200px;
    height: 780px;
}

.video-wrap {
    width: 100%;
    height: 100%;
}
.reviewed-list {
    list-style-type: none;
}

@media screen and (max-width: 950px) {
    #resp_logo-img {
        width: 75%;
    }
    #fn-proto-wrap {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #resp_fn-right-portion {
        width: 100%;
    }
    #resp_fn-proto-left {
        width: 100%;
    }
}

@media screen and (max-width: 1250px) {
    #resp_fn-proto-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
    }
    #resp_fn-proto-right-bar {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #resp_fn-proto-left-bar-contracts {
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    #resp_section-title {
        font-size: 40px;
    }

    
    #resp_fn-proto-text {
        width: 100%;
    }
    #resp_fn-proto-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 320px) {
    #resp_section-title {
        font-size: 18px;
    }
}

.ux-project-brief-wrap {
    background-image: linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151));
    
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: solid rgb(255, 47, 151);
    border-width: 0 3px;

    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 20px auto;
}

.ux-project-brief-text {
    text-align: justify;
    text-align-last: center;
    font-size: 16px;
}

.dl-pdf {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: rgb(100, 100, 100);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dl-pdf:hover {
    -webkit-filter: brightness(2.5);
            filter: brightness(2.5);
}

.dl-pdf:active {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

.dl-text {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    font-size: 32px;
}

.lancer-logo-wrap {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    margin: 25px auto 0px auto;
}

.section-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 25px 20px;
    margin: 0;
}

.section-content-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.section-title {
    font-size: 52px;
    text-align: center;
}

.section-sub-title {
    font-size: 14px;
}

.section-text {
    font-size: 16px;
    text-align: justify;
    text-align-last: center;
    width: 90%;
    margin: 20px auto;
}

.section-media-wrap {
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0px auto;
    justify-content: center;
}

/* final prototype css */

.section-content-wrapper-fn-proto {
    width: 80%;
    display: flex;
    margin: auto;
}

.fn-left-portion, .fn-right-portion {
    width: 50%;
}

.design_lang-img {
    width: 100%;
    margin-right: 20px;
    border-radius: 12px;
}

.fn-right-portion {
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    align-items: center;
}

#onboarding-title {
    text-align: center;
    font-size: 24px;
}

.onboard-text {
    text-align: justify;
    text-align-last: center;
}

/* panels of individual final prototypes */

.fn-proto-section {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 25px 20px;
    margin: 0;
}

.fn-proto-wrapper {
    display: flex;
    width: 60%;
    margin: auto;
}

.fn-proto-left-bar {
    width: 75%;
}

.fn-proto-right-bar {
    width: 25%;
}

.fn-proto-title {
    font-size: 24px;
    text-align: center;
}

.fn-proto-text {
    padding: 0px 20px;
    text-align: justify;
    text-align-last: center;
}

/* contracts section */

.fn-proto-contracts-wrapper {
    display: flex;
    width: 80%;
    margin: auto;
}

.fn-proto-left-bar-contracts {
    width: 50%;
}

.fn-proto-right-bar-contracts {
    width: 50%;
    display: flex;
}

.fn-proto-title {
    font-size: 24px;
    text-align: center;
}

/* section container colours */

#und_users-cont {
    background-color: rgb(50, 50, 50);
    color: white;
}

#fn-proto-cont {
    background-color: white;
    color: black;
}

#me-container {
    background-color: rgb(200, 200, 200);
    color: black;
}

#contracts-container {
    background-color: white;
    color: black;
}

#people-container {
    background-color: rgb(208, 208, 208);
    color: black;
}
