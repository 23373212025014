@media screen and (max-width: 950px) {
    #resp_logo-img {
        width: 75%;
    }
    #fn-proto-wrap {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #resp_fn-right-portion {
        width: 100%;
    }
    #resp_fn-proto-left {
        width: 100%;
    }
}

@media screen and (max-width: 1250px) {
    #resp_fn-proto-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
    }
    #resp_fn-proto-right-bar {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #resp_fn-proto-left-bar-contracts {
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    #resp_section-title {
        font-size: 40px;
    }

    
    #resp_fn-proto-text {
        width: 100%;
    }
    #resp_fn-proto-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 320px) {
    #resp_section-title {
        font-size: 18px;
    }
}

.ux-project-brief-wrap {
    background-image: linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151));
    
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: solid rgb(255, 47, 151);
    border-width: 0 3px;

    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 20px auto;
}

.ux-project-brief-text {
    text-align: justify;
    text-align-last: center;
    font-size: 16px;
}

.dl-pdf {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: rgb(100, 100, 100);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dl-pdf:hover {
    filter: brightness(2.5);
}

.dl-pdf:active {
    filter: brightness(0.5);
}

.dl-text {
    transform: rotate(90deg);
    font-size: 32px;
}

.lancer-logo-wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    margin: 25px auto 0px auto;
}

.section-container {
    width: 100%;
    height: fit-content;
    padding: 25px 20px;
    margin: 0;
}

.section-content-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.section-title {
    font-size: 52px;
    text-align: center;
}

.section-sub-title {
    font-size: 14px;
}

.section-text {
    font-size: 16px;
    text-align: justify;
    text-align-last: center;
    width: 90%;
    margin: 20px auto;
}

.section-media-wrap {
    display: flex;
    width: 100%;
    height: fit-content;
    margin: 0px auto;
    justify-content: center;
}

/* final prototype css */

.section-content-wrapper-fn-proto {
    width: 80%;
    display: flex;
    margin: auto;
}

.fn-left-portion, .fn-right-portion {
    width: 50%;
}

.design_lang-img {
    width: 100%;
    margin-right: 20px;
    border-radius: 12px;
}

.fn-right-portion {
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    align-items: center;
}

#onboarding-title {
    text-align: center;
    font-size: 24px;
}

.onboard-text {
    text-align: justify;
    text-align-last: center;
}

/* panels of individual final prototypes */

.fn-proto-section {
    width: 100%;
    height: fit-content;
    padding: 25px 20px;
    margin: 0;
}

.fn-proto-wrapper {
    display: flex;
    width: 60%;
    margin: auto;
}

.fn-proto-left-bar {
    width: 75%;
}

.fn-proto-right-bar {
    width: 25%;
}

.fn-proto-title {
    font-size: 24px;
    text-align: center;
}

.fn-proto-text {
    padding: 0px 20px;
    text-align: justify;
    text-align-last: center;
}

/* contracts section */

.fn-proto-contracts-wrapper {
    display: flex;
    width: 80%;
    margin: auto;
}

.fn-proto-left-bar-contracts {
    width: 50%;
}

.fn-proto-right-bar-contracts {
    width: 50%;
    display: flex;
}

.fn-proto-title {
    font-size: 24px;
    text-align: center;
}

/* section container colours */

#und_users-cont {
    background-color: rgb(50, 50, 50);
    color: white;
}

#fn-proto-cont {
    background-color: white;
    color: black;
}

#me-container {
    background-color: rgb(200, 200, 200);
    color: black;
}

#contracts-container {
    background-color: white;
    color: black;
}

#people-container {
    background-color: rgb(208, 208, 208);
    color: black;
}