@media screen and (max-width: 650px) {
    #resp_bio-core-wrapper, #resp_top-portion {
        flex-direction: column;
    }
    #prof_pic, #resp_pdf {
        margin: 0px auto;
    }
    #resp_intro-text {
        margin: 25px auto;
    }
    #resp_bio-intro-text {
        width: 80%;
    }
}

@media screen and (max-width: 850px) {
    #resp_bottom {
        flex-direction: column;
    }
    #edu_accordion, #skills_accordion, #exp_accordion {
        width: 75%;
        margin: 25px auto;
    }
}

.bio-core-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 50px 0px;
    margin-top: 50px;
}

.top-portion {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.prof_photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto 0px auto auto;
    background-image: url('../../img/prof_photo.png');
    background-size: contain;
}

.bio-intro-text {
    background-image: linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151));
    
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: solid rgb(255, 47, 151);
    text-align: justify;
    border-width: 0 3px;
    padding: 20px;
    width: 50%;
    margin: 0px auto;
    text-align-last: center;
    font-size: 16px;
}


.intro-text {
    background-image: linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151));
    
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: solid rgb(255, 47, 151);
    text-align: justify;
    border-width: 0 3px;
    padding: 20px;
    width: 50%;
    margin: 0px auto;
    text-align-last: center;
    font-size: 16px;
}

.pdf-dl-wrapper {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba(100, 100, 100);
    margin: auto auto auto 0px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
}

.pdf-dl-wrapper:hover {
    filter: brightness(2.5);
    color: black;
    font-weight: bolder;
}

.pdf-dl-wrapper:active {
    filter: brightness(2);
    color: red;
    font-weight: bolder;
}

/* bottom portion css */

.bottom-portion {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
}

.accordion-wrapper {
    width: 35%;
    background-color: rgba(0, 0, 0);
    border: none;
    border-radius: 4px;
    text-align: left;
    outline: none;
    transition: 0.4s ease-in-out;
    margin: 0px auto auto auto;
}

.btn-wrap {
    display: flex;
    align-items: center;
    background-color: rgba(25, 25, 25);
    border-radius: 4px 4px 0px 0px;
    height: 40px;
    cursor: pointer;
}

.accordion-btn {
    width: 10%;
    height: 100%;
    border: none;
    background: none;
    color: rgb(255, 0, 221);
    cursor: pointer;
    font-size: 20px;
    font-weight: bolder;
}

.chevron-rotate {
    transform: rotate(90deg);
}

.btn-wrap:hover {
    filter: brightness(0.5);
}

.btn-title {
    width: 90%;
    text-align: left;
    margin: auto 0px auto auto;
    font-family: clarendon-wide, sans-serif;
    color: rgb(255, 0, 221);
}

.btn-title-active {
    font-weight: bolder;
}

.accordion-panel {
    height: fit-content;
    padding: 10px 20px;
    background-color: rgba(50, 50, 50);
    border-radius: 0px 0px 4px 4px;
    display: none;
}

.panel-wrap {
    margin: 0;
    text-align: justify;
}

.panel_toggle {
    display: block;
    transition: 0.2s ease-in-out;
}

.panel-list-title {
    font-weight: bolder;
}

.panel-list-item {
    list-style-type: none;
    font-size: 16px;
}

.panel-sub-item {
    font-size: 14px;
}

.extra-title {
    color: rgb(255, 0, 221);
    font-weight: bolder;
}

.skills-item {
    font-size: 16px;
    font-weight: bold;
}

.sub-item {
    list-style-type: none;
}

.sub-item:before {
    content: "\00BB";
}