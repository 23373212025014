@media screen and (max-width: 650px) {
    #resp_module-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #resp_email-module {
        width: 75%;
    }
    #resp_social-module {
        margin-top: 50px;
        width: 75%;
    }
    #resp_display-wrap {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {
    #resp_display-wrap {
        width: 75%;
    }
}

.contact-wrapper {
    width: 75%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
}

.contact-title-wrap {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: auto;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.display-wrapper {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.02);
    border-radius: 8px;
    padding: 0px 20px;
    width: 50%;
    text-align: center;
}

.module-wrapper {
    display: flex;
    width: 100%;
    padding: 20px 0px;
    margin: 50px 0px;
}

.email-module-wrap {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.contact-input-field {
    height: 35px;
    border-radius: 6px;
    margin: 0px 0px 10px 0px;
    padding: 5px;
    border: none;
    color: rgb(255, 77, 106);
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.02);
}

::placeholder {
    color: rgb(201, 201, 201);
}

#msg-input {
    height: 75px;
    padding: 10px;
    margin: 10px 0px;
    font-family: Arial, sans-serif;
}

.submit-btn {
    background-color: orange;
    color: white;
    height: 40px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
}

.submit-btn:hover {
    filter: brightness(0.5);
    color: black;
}

.submit-btn:active {
    filter: brightness(1.5);
    color: black;
}

/* right portion - link side */

.social-module-wrap {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.phone-img, .linkedin-img {
    width: 35px;
    height: 35px;
}

.phone-wrap, .linkedin-wrap {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: baseline;
}

.phone-img {
    background-image: url('../../img/phone-square-solid.svg');
    background-size: cover;
}

.linkedin-img {
    background-image: url('../../img/linkedin-brands.svg');
    background-size: cover;
}

.social-contact-text {
    margin-left: 20px;
    cursor: pointer;
}

#linkedin_link:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
    filter: brightness(0.5);
}

#linkedin_link:active {
    filter: brightness(0.75);
}

.linkedin-link {
    text-decoration: none;
    color: white;
}