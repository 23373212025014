@media screen and (max-width: 1250px) {
    #resp_demo {
        width: 900px;
        height: 580px;
    }
}

@media screen and (max-width: 950px) {
    #resp_demo {
        width: 720px;
        height: 480px;
    }
}

@media screen and (max-width: 750px) {
    #resp_demo {
        width: 600px;
        height: 420px;
    }
    #resp_proj-title {
        font-size: 42px;
    }
    #resp_brief-text {
        width: 90%;
    }
}

@media screen and (max-width: 620px) {
    #resp_demo {
        width: 450px;
        height: 280px;
    }
}

@media screen and (max-width: 480px) {
    #resp_demo {
        width: 320px;
        height: 180px;
    }
    #resp_proj-title {
        font-size: 28px;
    }
    #resp_brief-text, #resp_url, #resp_github {
        font-size: 14px;
    }
}

.project-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-title {
    font-size: 52px;
    text-align: center;
    padding: 0 20px;
}

.project-brief-text {
    background-image: linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151)),
      linear-gradient(rgb(255, 47, 151), rgb(255, 47, 151));
    
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, top right, bottom left, bottom right;
  
    border: solid rgb(255, 47, 151);
    border-width: 0 3px;


    padding: 20px;
    width: 60%;
    margin: 20px auto;
    text-align: justify;
    text-align-last: center;
    font-size: 16px;
}

.live-url-text {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    color: blueviolet;
}

.live-url-link {
    color: blueviolet;
    text-decoration: none;
}

.live-url-link:hover, .github-link:hover {
    filter: brightness(2);
    text-decoration: underline;
    text-underline-offset: 5px;
}

.github-link-text {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    color: greenyellow;
}

.github-link {
    color: greenyellow;
    text-decoration: none;
}

.demo-video-wrap {
    position: relative;
    overflow: hidden;
    margin: 50px auto;
    border-radius: 12px;
    width: 1200px;
    height: 780px;
}

.video-wrap {
    width: 100%;
    height: 100%;
}