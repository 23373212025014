@media screen and (max-width: 650px) {
    #resp_works-wrapper {
        margin-top: 0px;
    }
}

@media screen and (max-width: 850px) {
    #resp_proj-menu-wrap {
        width: 75%;
        top: 25%;
    }
}

@media screen and (max-width: 550px) {
    #resp_works-wrapper {
        padding: 0;
    }
    #resp_proj-menu-wrap {
        flex-direction: column;
        margin: auto;
        height: fit-content;
        position: static;
        transform: none;
        top: none;
        left: none;
    }
}


.works-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 50px 0px;
    margin-top: 50px;
}

.proj-menu-wrap {
    width: 50%;
    height: 50px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.proj-nav-link {
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: rgba(100, 100, 100);
    
}


/* projects active and hover links */

.blog-link-active {
    color: black;
    text-shadow:
        0 0 5px rgb(255, 115, 0),
        0 0 5px rgb(255, 115, 0),
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0), 
        0 0 5px rgb(255, 115, 0);
}

#blog-nav-link:hover {
    color: rgb(255, 166, 0);
}

.turners-link-active {
    color: black;
    text-shadow:
        0 0 5px #ea3642,
        0 0 5px #ea3642,
        0 0 5px #ea3642, 
        0 0 5px #ea3642, 
        0 0 5px #ea3642, 
        0 0 5px #ea3642, 
        0 0 5px #ea3642, 
        0 0 5px #ea3642;
}

#level-nav-link:hover {
    color: #ea3642;
}

.levelup-link-active {
    color: black;
    text-shadow:
        0 0 5px #00bdc4,
        0 0 5px #00bdc4,
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4, 
        0 0 5px #00bdc4;
}

#level-nav-link:hover {
    color: #43C0F6;
}

.gate-link-active {
    color: black;
    text-shadow:
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255),
        0 0 5px rgb(200, 0, 255);
}

#gate-nav-link:hover {
    color: rgb(203, 89, 255);
}

.ux-link-active {
    color: black;
    text-shadow:
        0 0 3px rgb(0, 220, 150),
        0 0 3px rgb(0, 220, 150),
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150), 
        0 0 3px rgb(0, 220, 150);
}

#ux-nav-link:hover {
    color: rgb(0, 118, 0);
}

/* end */

.proj-link-text {
    font-weight: bolder;
    font-size: 14px;
}

/* content wrapper css */

.project-content-wrapper {
    width: 100%;
    padding: 25px 0px;
    margin: 50px auto;
}